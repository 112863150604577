import { IPreview } from '@/types/components/preview';
import { IToast } from '@/types/components/toast';
import dayjs from 'dayjs';
import _ from 'lodash';

export const openCrisp = () => {
  try {
    $crisp.push(['do', 'chat:open']);
  } catch (error) {
    console.log(error);
  }
};

export const getSomeDaysAgo = (day: number): Date => {
  const date = new Date();
  date.setDate(date.getDate() - day);
  date.setHours(0, 0, 0);
  return date;
};

export const formatDate = (date: number | Date, format?: string) => {
  return dayjs(typeof date === 'number' ? date * 1000 : date).format(format ? format : 'D MMM YYYY, h:mm a');
};

export const getLastMonth = () => {
  const firstDate = new Date();
  firstDate.setDate(1);
  firstDate.setMonth(firstDate.getMonth() - 1);
  const lastDate = new Date();
  lastDate.setDate(0);
  return {
    start: firstDate,
    end: lastDate,
  };
};
export const getLastSomesMonth = (month: number) => {
  const firstDate = new Date();
  firstDate.setDate(1);
  firstDate.setMonth(firstDate.getMonth() - month);
  const lastDate = new Date();
  lastDate.setMonth(lastDate.getMonth() - month + 1);
  lastDate.setDate(0);
  return {
    start: firstDate,
    end: lastDate,
  };
};

export const dateToTimeStamp = (date: Date, isOrigin?: boolean) => {
  return isOrigin ? +date : dayjs(date).unix() * 1000;
};

export const getStoreNameFromStoreUrl = (storeUrl: string) => storeUrl.match(/^(.*?)\.myshopify\.com/)?.[1];

export const checkShowErrorInline = (
  res: any,
): {
  statusCode: boolean;
  message: string;
} => {
  try {
    if ('data' in res) {
      return {
        statusCode: res.data.statusCode !== 200,
        message: res.data.message,
      };
    } else {
      return {
        statusCode: true,
        message: res.error.data.message.toString() || 'Something happened',
      };
    }
  } catch (err) {
    return {
      statusCode: true,
      message: 'Something happened',
    };
  }
};

export const handleToastMutation = (res: any): IToast => {
  try {
    if ('data' in res) {
      if (res.data.statusCode > 302) {
        return {
          isOpen: true,
          content: res.data.message || 'Failed',
          error: true,
        };
      } else {
        return {
          isOpen: true,
          content: 'Saved',
          error: false,
        };
      }
    } else {
      return {
        isOpen: true,
        content: res.data.message || 'Something happened',
        error: false,
      };
    }
  } catch (err) {
    return {
      isOpen: true,
      content: 'Something happened',
      error: true,
    };
  }
};

export function convertNumberToStringInJson(obj: IPreview): IPreview {
  return _.cloneDeepWith(obj, (value) => {
    if (typeof value === 'number') {
      return value.toString();
    }
    if (value === null) {
      return '';
    }
  });
}

export function convertStringToNumberInJson(obj: IPreview) {
  return _.cloneDeepWith(obj, (value) => {
    if (typeof value === 'string' && !isNaN(Number(value)) && value.trim() !== '') {
      return Number(value);
    }
  });
}

export const truncateText = (text: string, maxLength: number) => {
  if (!text) return '';

  if (text.length > maxLength) {
    return text.slice(0, maxLength) + '...';
  }
  return text;
};

export function validateUrl(url: string) {
  // eslint-disable-next-line no-useless-escape
  const regex = /[(www\.)?a-zA-Z0-9@:%._\+\-~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/i;
  const result = url.toLowerCase().match(regex);
  if (result === null) return false;
  return true;
}

export function validateDomain(url: string, listValidDomain: string[]) {
  if (!/^https?:\/\//i.test(url)) {
    url = 'https://' + url;
  }
  try {
    const parsedUrl = new URL(url);
    return listValidDomain.some(domain => parsedUrl.hostname.toLocaleLowerCase() === domain.toLowerCase());
  } catch {
    return false;
  }
}

export const formatCreatedAt = (createdAt: number) => {
  if (createdAt === 0) {
    return '';
  }
  const currentTime = new Date().getTime();
  const timeDiff = currentTime - createdAt;
  const seconds = Math.floor(timeDiff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const months = Math.floor(days / 30);
  const years = Math.floor(days / 365);

  if (minutes <= 1) {
    return `1 minute ago`;
  } else if (minutes < 60) {
    return `${minutes} minutes ago`;
  } else if (hours <= 1) {
    return `${hours} hour ago`;
  } else if (hours < 24) {
    return `${hours} hours ago`;
  } else if (days <= 1) {
    return `${days} day ago`;
  } else if (days < 30) {
    return `${days} days ago`;
  } else if (months <= 1) {
    return `${months} month ago`;
  } else if (months < 12) {
    return `${months} months ago`;
  } else if (years <= 1) {
    return `${years} year ago`;
  } else {
    return `${years} years ago`;
  }
};

export const areArraysEqual = (arr1: number[], arr2: number[]): boolean => {
  if (!arr1 || !arr2) {
    return false;
  }
  if (arr1.length !== arr2.length) return false;
  console.log("arr1", arr1)
  console.log("arr2", arr2)
  return arr1.sort((a, b) => a - b).every((value, index) => value === arr2.sort((a, b) => a - b)[index]);
};

export const isSubArray = (a: number[], b: number[]): boolean => {
  return b.every(value => a.includes(value));
}