import { displayPageList, perPageOptions, triggerCondition } from '@/constants/options';
import { handlePopupListTable, popupListTableSelector } from '@/redux/slice/preview.slice';
import { ChoiceList } from '@shopify/polaris';
import { useDispatch, useSelector } from 'react-redux';

export const PopupListFilter = () => {
  const dispatch = useDispatch();
  const popupListTable = useSelector(popupListTableSelector);
  const filters = [
    {
      key: 'target',
      label: 'Target',
      filter: (
        <ChoiceList
          title="Target"
          titleHidden
          choices={[...displayPageList]}
          selected={[popupListTable.pageTypeToShow || '']}
          onChange={(value) =>
            dispatch(handlePopupListTable({
              ...popupListTable,
              pageTypeToShow: value[0] || undefined,
              page: 1,
            }),
            )
          }
        />
      ),
      shortcut: true,
    },
    {
      key: 'trigger',
      label: 'Trigger',
      filter: (
        <ChoiceList
          title="Trigger"
          titleHidden
          choices={[...triggerCondition]}
          selected={[popupListTable.applyCustomer || '']}
          onChange={(value) =>
            dispatch(handlePopupListTable({
              ...popupListTable,
              ...(value[0] !== '' && { applyCustomer: value[0] }),
              page: 1,
            }),
            )
          }
        />
      ),
      shortcut: true,
    },
    {
      key: 'perPage',
      label: 'Per page',
      filter: (
        <ChoiceList
          title="Per page"
          titleHidden
          choices={perPageOptions}
          selected={[popupListTable.limit]}
          onChange={(value) =>
            dispatch(handlePopupListTable({
              ...popupListTable,
              limit: value[0],
              page: 1,
            }),
            )
          }
        />
      ),
      shortcut: true,
    },
  ];

  const appliedFilters = [
    {
      onRemove: () =>
        dispatch(
          handlePopupListTable({
            ...(() => {
              const { pageTypeToShow, ...rest } = popupListTable;
              return rest;
            })(),
            page: 1,
          })
        ),
      key: 'target',
      label: `Target: ${displayPageList.find((item) => item.value === popupListTable.pageTypeToShow)?.label || 'All'}`,
      hidden: !popupListTable.pageTypeToShow,
    },
    {
      onRemove: () =>
        dispatch(
          handlePopupListTable({
            ...(() => {
              const { applyCustomer, ...rest } = popupListTable;
              return rest;
            })(),
            page: 1,
          })
        ),
      key: 'trigger',
      label: `Trigger: ${triggerCondition.find((item) => item.value === popupListTable.applyCustomer)?.label || 'All'}`,
      hidden: !popupListTable.applyCustomer,
    },
    {
      onRemove: () =>
        dispatch(
          handlePopupListTable({
            ...popupListTable,
            limit: '10',
            page: 1,
          }),
        ),
      key: 'perPage',
      label: 'Record per page: ' + popupListTable.limit,
    },
  ];

  return {
    filters,
    appliedFilters: appliedFilters.filter((item) => !item.hidden),
  };
};
