import BoldText from '@/components/BoldText';
import { ContextualBar } from '@/components/ContextualBar';
import Layout from '@/components/layout';
import { config } from '@/config';
import { Embedded } from '@/constants/enum';
import { checkShowErrorInline, handleToastMutation } from '@/helpers';
import { apiCaller } from '@/redux/query';
import { initialState } from '@/redux/slice/shop.slice';
import { appStatusSelector } from '@/redux/slice/shop.slice';
import { handleToast } from '@/redux/slice/Toast/toast.slice';
import {
  Badge,
  Box,
  Button,
  Card,
  Divider,
  InlineGrid,
  InlineStack,
  Page,
  Layout as ShopifyLayout,
  Text,
  TextField,
  useBreakpoints
} from '@shopify/polaris';
import { isEqual } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

const Settings = () => {
  const dispatch = useDispatch();
  const { state } = useLocation();

  const [updateShopSetting, { isLoading }] = apiCaller.useUpdateAppSettingsMutation();
  const { data: shopGeneral } = apiCaller.useGetGeneralDetailQuery(undefined);
  const { smUp } = useBreakpoints();

  const [appSetting, setAppSetting] = useState({
    appStatus: true,
    cacheTime: 10
  });
  const appStatus = useSelector(appStatusSelector);

  const appGeneralRef = useRef(appSetting);

  const handleChangeAppStatusValue = useCallback(() => {
    setAppSetting((pre) => ({ ...pre, appStatus: !appStatus }));
  }, [appStatus]);

  const handleChangeCacheTimeValue = useCallback((value: string) => {
    setAppSetting((pre) => ({ ...pre, cacheTime: Number(value) <= 0 ? 0 : parseInt(value) }));
  }, []);

  const areSettingEqual = useMemo(() => {
    return isEqual(appSetting, appGeneralRef.current);
  }, [appSetting, appGeneralRef.current]);

  const handleDiscard = () => {
    setAppSetting({ ...appGeneralRef.current });
  };

  const handleSave = async () => {
    const res = await updateShopSetting({
      appStatus: Boolean(appSetting.appStatus),
      cacheTime: appSetting.cacheTime
    })

    if ('data' in res) {
      appGeneralRef.current = { ...appSetting };
      dispatch(handleToast(handleToastMutation(res)));
      return;
    } else {
      const isError = checkShowErrorInline(res);
      if (isError.statusCode) {
        dispatch(handleToast(handleToastMutation(isError.message)));
      }
    }
  };

  const layoutSettings =
    config.embedded !== Embedded.LIVE
      ? {
        primaryAction: {
          content: 'Save',
          onAction: handleSave,
          disabled: areSettingEqual,
          loading: isLoading,
        },
        secondaryActions: (
          <div style={{ display: 'flex', gap: '8px' }}>
            <Button disabled={areSettingEqual} onClick={handleDiscard}>
              Discard
            </Button>
          </div>
        ),
      }
      : null;

  useEffect(() => {
    if (shopGeneral && shopGeneral.data) {
      setAppSetting({
        appStatus: shopGeneral.data.appStatus,
        cacheTime: shopGeneral.data.cacheTime
      });
      appGeneralRef.current = {
        appStatus: shopGeneral.data.appStatus,
        cacheTime: shopGeneral.data.cacheTime
      }
    }
  }, [shopGeneral]);

  return (
    <Layout title="Settings" {...layoutSettings}>
      <Page>
        <ShopifyLayout>
          {config.embedded === Embedded.LIVE ? (
            <>
              <ContextualBar
                delay={state?.prePath}
                value={appSetting}
                loadingSave={isLoading}
                disabledDiscard={areSettingEqual}
                disabledSave={
                  areSettingEqual
                }
                onDiscard={handleDiscard}
                onSave={handleSave}
              />
            </>
          ) : null}
          <ShopifyLayout.AnnotatedSection
            id="general"
            title="General"
            description="Manage the activation status of age verification to control store access."
          >
            <Card>
              <InlineGrid columns={"1fr auto"}>
                <Box paddingBlockStart={"100"}>
                  <InlineStack gap={'150'}>
                    <Text as="h1" variant="headingSm">
                      Active Age Verification{" "} {appSetting.appStatus ? <Badge size='small' tone='success'>On</Badge> : <Badge size='small' tone='warning'>Off</Badge>}
                    </Text>
                  </InlineStack>
                </Box>
                <Button onClick={handleChangeAppStatusValue} loading={isLoading}>{appSetting.appStatus ? 'Turn off' : 'Turn on'}</Button>
              </InlineGrid>
            </Card>
          </ShopifyLayout.AnnotatedSection>

          {smUp ? <Divider /> : null}

          <ShopifyLayout.AnnotatedSection
            id="storeDetails"
            title="Advanced"
            description="Set how long visitors are remembered before requiring verification again."
          >
            <Card>
              {/* <FormLayout> */}
              <TextField
                autoComplete=""
                label={<BoldText>Remember visitor for</BoldText>}
                value={appSetting.cacheTime?.toString()}
                type="number"
                onChange={handleChangeCacheTimeValue}
                onBlur={() => {
                  if (appSetting.cacheTime === null || appSetting.cacheTime === undefined)
                    handleChangeCacheTimeValue(Number(initialState.cacheTime).toFixed());
                }}
                suffix="day(s)"
              />
              {/* </FormLayout> */}
            </Card>
          </ShopifyLayout.AnnotatedSection>
        </ShopifyLayout>
      </Page>
    </Layout >
  );
};

export default Settings;
