import { DateOrder, InputMethod, PageType, StatusType, TriggerType } from "./enum";

export const perPageOptions = [
  {
    label: '10',
    value: '10',
  },
  {
    label: '25',
    value: '25',
  },
  {
    label: '50',
    value: '50',
  },
  {
    label: '100',
    value: '100',
  },
];

export const optionAgeList = [...Array(100).keys()].map((i) => ({ value: String(i + 1), label: String(i + 1) }));

export const choiceMethodList = [
  { label: 'No input', value: InputMethod.NONE },
  { label: 'Birthdate entry', value: InputMethod.BIRTHDATE_ENTRY },
];

export const triggerCondition = [
  { label: 'Always show', value: TriggerType.AlwaysShow },
  { label: 'Logged customers', value: TriggerType.LoggedCustomers },
];

export const status = [
  { label: 'Enabled', value: StatusType.Enable },
  { label: 'Disabled', value: StatusType.Disable },
]

export const optionDateOrderList = [
  { label: 'YY,MM,DD', value: DateOrder.YY_MM_DD },
  { label: 'MM,DD,YY', value: DateOrder.MM_DD_YY },
  { label: 'DD,MM,YY', value: DateOrder.DD_MM_YY },
  { label: 'YY,DD,MM', value: DateOrder.YY_DD_MM },
  { label: 'MM,YY,DD', value: DateOrder.MM_YY_DD },
  { label: 'DD,YY,MM', value: DateOrder.DD_YY_MM },
];

export const displayPageList = [
  { label: 'All pages', value: PageType.All },
  { label: 'Home page', value: PageType.Home },
  { label: 'Specific collections', value: PageType.SpecificCollections },
  { label: 'Specific products', value: PageType.SpecificProducts },
  { label: 'Custom page', value: PageType.Custom }
];

export const choiceDisplayOptionSpecific = [
  {
    label: 'Home',
    value: PageType.Home,
  },
  {
    label: 'Collection',
    value: PageType.SpecificCollections,
  },
  {
    label: 'Product',
    value: PageType.SpecificProducts,
  },
  {
    label: 'Custom',
    value: PageType.Custom,
  },
];
