import BoldText from '@/components/BoldText';
import { colorsCheckoutUI } from '@/constants/colors';
import { CheckoutVerifyType } from '@/constants/enum';
import { optionDateOrderList } from '@/constants/options';
import userPlans from '@/hooks/userPlans';
import {
  CheckoutApplyType,
  CheckoutValidateMethodType,
  ColorsCheckoutUITypes,
  handleChangeSetupVerify,
  handleSetupErrorVerify,
  setupErrorVerifySelector,
  setupVerifySelector,
  verifyTypeSelector
} from '@/redux/slice/checkoutVerification.slice';
import { BlockStack, Card, ChoiceList, Collapsible, Divider, Select, Text, TextField } from '@shopify/polaris';
import { useDispatch, useSelector } from 'react-redux';
import ProductOrCollectionList from './ProductOrCollectionList';

const PreviewColor = ({ bgColor }: { bgColor: string }) => (
  <div
    style={{
      width: '30px',
      height: '30px',
      marginRight: '6px',
      borderRadius: '50%',
      backgroundColor: bgColor,
    }}
  />
);

const optionAgeList = [...Array(100).keys()].map((i) => ({ value: String(i + 1), label: String(i + 1) }));
export const optionsColor = [
  {
    label: 'Accent',
    value: ColorsCheckoutUITypes.ACCENT,
    prefix: <PreviewColor bgColor={colorsCheckoutUI.accent} />,
  },
  {
    label: 'Critical',
    value: ColorsCheckoutUITypes.CRITICAL,
    prefix: <PreviewColor bgColor={colorsCheckoutUI.critical} />,
  },
  {
    label: 'Decorative',
    value: ColorsCheckoutUITypes.DECORATIVE,
    prefix: <PreviewColor bgColor={colorsCheckoutUI.decorative} />,
  },
  {
    label: 'Info',
    value: ColorsCheckoutUITypes.INFO,
    prefix: <PreviewColor bgColor={colorsCheckoutUI.info} />,
  },
  {
    label: 'Subdued',
    value: ColorsCheckoutUITypes.SUBDUED,
    prefix: <PreviewColor bgColor={colorsCheckoutUI.subdued} />,
  },
  {
    label: 'Success',
    value: ColorsCheckoutUITypes.SUCCESS,
    prefix: <PreviewColor bgColor={colorsCheckoutUI.success} />,
  },
  {
    label: 'Warning',
    value: ColorsCheckoutUITypes.WARNING,
    prefix: <PreviewColor bgColor={colorsCheckoutUI.warning} />,
  },
];

const ConditionVerify = () => {
  const dispatch = useDispatch();
  const { shopifyPlanPlus } = userPlans();

  const verifyType = useSelector(verifyTypeSelector);
  const setupVerify = useSelector(setupVerifySelector);
  const setupError = useSelector(setupErrorVerifySelector);

  const handleChangeSetup = (key: keyof typeof setupVerify) => (value: string | boolean) => {
    dispatch(
      handleChangeSetupVerify({
        [key]: value,
        ...(key === 'applyTypeBanner' && { showInPageBanner: [] }),
        ...(key === 'applyTypeInput' && { showInPageInput: [] }),
      }),
    );
  };

  return (
    <BlockStack gap="200">
      <Card>
        <ChoiceList
          title={<BoldText>{verifyType === CheckoutVerifyType.InfoBanner ? 'Banner status' : 'Status'} </BoldText>}
          choices={[
            {
              label: 'Enable',
              value: 'true',
            },
            {
              label: 'Disable',
              value: 'false',
            },
          ]}
          selected={[
            verifyType === CheckoutVerifyType.InfoBanner
              ? setupVerify.bannerActive.toString()
              : setupVerify.validateInputActive.toString(),
          ]}
          onChange={(value) =>
            handleChangeSetup(verifyType === CheckoutVerifyType.InfoBanner ? 'bannerActive' : 'validateInputActive')(
              value[0] === 'true' ? true : false,
            )
          }
        />
      </Card>

      {shopifyPlanPlus && verifyType === CheckoutVerifyType.CheckboxOrDate ? (
        <Card>
          <Select
            value={setupVerify?.age?.toString()}
            options={optionAgeList}
            label={<BoldText>Entry age</BoldText>}
            onChange={handleChangeSetup('age')}
          />
        </Card>
      ) : null}

      <Card>
        <ChoiceList
          title={<BoldText>Target</BoldText>}
          choices={[
            { label: 'Always', value: CheckoutApplyType.All, helpText: 'Always show the banner without any conditions.' },
            {
              label: 'Specific collection',
              value: CheckoutApplyType.SpecificCollection,
              helpText: 'Show the banner to selected collection.',
              renderChildren: (isSelected: boolean) => isSelected && <ProductOrCollectionList />,
            },
            {
              label: 'Specific product',
              value: CheckoutApplyType.SpecificProduct,
              helpText: 'Show the banner to selected product.',
              renderChildren: (isSelected: boolean) => isSelected && <ProductOrCollectionList />,
            },
          ]}
          selected={[verifyType === CheckoutVerifyType.InfoBanner ? setupVerify.applyTypeBanner : setupVerify.applyTypeInput]}
          onChange={(value) => {
            if (setupError.showInPage) dispatch(handleSetupErrorVerify({ showInPage: '' }));
            handleChangeSetup(verifyType === CheckoutVerifyType.InfoBanner ? 'applyTypeBanner' : 'applyTypeInput')(value[0]);
          }}
        />
      </Card>

      {shopifyPlanPlus && verifyType === CheckoutVerifyType.CheckboxOrDate ? (
        <Card>
          <div className="mb-8">
            <BlockStack gap="200">
              <Text as="h4" variant="headingMd">
                Configuration
              </Text>
              <Divider />
            </BlockStack>
          </div>

          <BlockStack gap="500">
            <ChoiceList
              title={'Method'}
              choices={[
                {
                  label: 'Checkbox',
                  value: CheckoutValidateMethodType.CheckoutBox,
                },
                {
                  label: 'Date of birth',
                  value: CheckoutValidateMethodType.Input,
                },
              ]}
              selected={[setupVerify.method || '']}
              onChange={(value) => {
                dispatch(handleSetupErrorVerify({ checkboxText: '', headingInput: '' }));
                handleChangeSetup('method')(value[0]);
              }}
            />

            <Collapsible
              open={setupVerify.method === CheckoutValidateMethodType.CheckoutBox}
              id=""
              transition={{ duration: '300ms', timingFunction: 'ease-in-out' }}
            >
              <BlockStack gap="500">
                <TextField
                  maxLength={255}
                  showCharacterCount
                  autoComplete=""
                  label="Text"
                  value={setupVerify.checkboxText}
                  onChange={(value) => {
                    if (setupError.checkboxText) dispatch(handleSetupErrorVerify({ checkboxText: '' }));
                    handleChangeSetup('checkboxText')(value);
                  }}
                  onBlur={() => {
                    if (setupVerify.checkboxText.trim().length === 0)
                      dispatch(handleSetupErrorVerify({ checkboxText: 'Value is required' }));
                  }}
                  requiredIndicator
                  error={setupError.checkboxText}
                />
                <Select
                  label="Color"
                  options={optionsColor}
                  onChange={handleChangeSetup('textColor')}
                  value={setupVerify.textColor}
                />
              </BlockStack>
            </Collapsible>

            <Collapsible
              open={setupVerify.method === CheckoutValidateMethodType.Input}
              id=""
              transition={{ duration: '300ms', timingFunction: 'ease-in-out' }}
            >
              <BlockStack gap="500">
                <Select
                  options={optionDateOrderList}
                  label="Date order"
                  value={setupVerify.dateOrder}
                  onChange={handleChangeSetup('dateOrder')}
                />
                <TextField
                  autoComplete=""
                  label="Heading"
                  value={setupVerify.headingInput}
                  onChange={(value) => {
                    if (setupError.headingInput) dispatch(handleSetupErrorVerify({ headingInput: '' }));
                    handleChangeSetup('headingInput')(value);
                  }}
                  onBlur={() => {
                    if (setupVerify.headingInput.trim().length === 0)
                      dispatch(handleSetupErrorVerify({ headingInput: 'Value is required' }));
                  }}
                  requiredIndicator
                  error={setupError.headingInput}
                  showCharacterCount
                  maxLength={255}
                />
              </BlockStack>
            </Collapsible>
          </BlockStack>
        </Card>
      ) : null}
    </BlockStack>
  );
};

export default ConditionVerify;
