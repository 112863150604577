export enum BREAKPOINT {
  XS = 490,
  SM = 768,
  MD = 1040,
  LG = 1800,
  XL = 1920,
}

export enum Subscription {
  Monthly = 'monthly',
  Annualy = 'yearly',
}

export enum Plan {
  FREE = 'free',
  CHARGE = 'charge',
}

export enum CustomLabelForMonthStatus {
  HIDE = 0,
  SHOW = 1,
}

export enum Embedded {
  LIVE = '1',
  DEV = '0',
}
export enum Month {
  January = 'January',
  February = 'February',
  March = 'March',
  April = 'April',
  May = 'May',
  June = 'June',
  July = 'July',
  August = 'August',
  September = 'September',
  October = 'October',
  November = 'November',
  December = 'December',
}

export enum BackgroundColor {
  Black = '#000000',
  WhiteTransparent = '#FFFFFFD9',
  White = '#FFFFFF',
  BrightOrange = '#FE4D01',
  LightGray = '#A0A0A0',
}

export enum BackgroundType {
  Transparent = '1',
  BackgroundImage = '2',
  BackgroundColor = '3',
}

export enum PageType {
  All = '0',
  Home = '1',
  SpecificCollections = '2',
  SpecificProducts = '3',
  SpecificPages = '4',
  Custom = '5',
}

export enum InputMethod {
  NONE = '0',
  BIRTHDATE_ENTRY = '1',
}

export enum DateOrder {
  YY_MM_DD = 'YY,MM,DD',
  MM_DD_YY = 'MM,DD,YY',
  DD_MM_YY = 'DD,MM,YY',
  YY_DD_MM = 'YY,DD,MM',
  MM_YY_DD = 'MM,YY,DD',
  DD_YY_MM = 'DD,YY,MM',
}

export enum RedirectUrl {
  Google = 'https://www.google.com/',
}

export enum DeviceType {
  MOBILE = 'mobile',
  DESKTOP = 'desktop',
}

export enum CheckoutVerifyType {
  DEFAULT = 'default',
  InfoBanner = 'infoBanner',
  CheckboxOrDate = 'checkboxOrDate',
}
export enum Ga4Event {
  ImpressTwitter = 'impress_twitter',
  ClickTwitter = 'click_twitter',
  ParetoClick = 'click_limit',
  CheckoutRulesClick = 'click_checkout',
  SynctrackBannerClick = 'click_synctrack',
  ClickCookies = 'click_cookies',
  FraudBannerClick = 'click_fraud'
}

export enum StatusType {
  Enable = 'enable',
  Disable = 'disable',
}

export enum TriggerType {
  AlwaysShow = '0',
  LoggedCustomers = '1',
}

export enum ApplyRule {
  ALL = 'all',
  SPECIFIC = 'specific'
}