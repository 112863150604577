import BoldText from '@/components/BoldText';
import Layout from '@/components/layout';
import RegularText from '@/components/RegularText';
import { apiCaller } from '@/redux/query';
import bannerSlice, { bannerNoticeEmbeddedAppSelector, showReferAppSelector } from '@/redux/slice/banner.slice';
import { Banner, Button, Card, Collapsible, Icon, InlineGrid, Page, Text } from '@shopify/polaris';
import { BookIcon, ChatIcon, GlobeAsiaFilledIcon } from '@shopify/polaris-icons';
import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { link } from '@/constants/link';
import { useDispatch, useSelector } from 'react-redux';
import FAQ from './FAQ';
import Overview from './Overview';
import { BannerStype, DashboardStyled } from './styled';
import ThingsToDoNext from './thingsToDoNext';
import { isSkipApiSelector } from '@/redux/slice/auth.slice';
import BannerTemplate from '@/components/BannerTemplate';
import { images } from '@/asset';
import { gaFourSelector } from '@/redux/slice/session.slice';
import { Embedded, Ga4Event } from '@/constants/enum';
import { useGa4 } from '@/hooks/useGa4';
import RecommenedApps from './recommenedApps';
import { PATH } from '@/constants/path';
import { useLocation } from 'react-router-dom';
import { config } from '@/config';

const Dashboard = () => {
  const twitterRef = useRef(null);
  const recommendAppRef = useRef(null);
  const dispatch = useDispatch();
  const isSkip = useSelector(isSkipApiSelector);
  const showReferApp = useSelector(showReferAppSelector)
  const bannerNoticeEmbeddedApp = useSelector(bannerNoticeEmbeddedAppSelector);
  const checkEmbeddedStatus = apiCaller.useCheckAppEmbedStatusQuery(undefined, { skip: isSkip });
  const fetchDataSettings = apiCaller.useGetGeneralDetailQuery('', { skip: isSkip });
  const gaFour = useSelector(gaFourSelector);
  const { handleGa4 } = useGa4();
  const location = useLocation();

  const [showBannerReferApp, setShowBannerReferApp] = useState(false);

  const infoAndSupport = useMemo(() => {
    return [
      {
        title: 'Contact support',
        description: (
          <span>
            We provide <b>24/7</b> support, feel free to contact us if you get any problems with the app.
          </span>
        ),
        onAction: () => {
          const sendText = 'I need assistance with using Age Verify';
          try {
            $crisp.push(['set', 'session:event', ['ReviewStoreAgeVerification']]);
            $crisp.push(['do', 'chat:open']);
            $crisp.push(['do', 'message:send', ['text', sendText]]);
          } catch (err) {
            console.log(err);
          }
        },
        btnContent: 'Chat with us',
        icon: ChatIcon,
      },
      {
        title: 'Read user guideline',
        description: 'Step-by-step instruction articles to guide you in setting up rules in the easiest way.',
        onAction: () => {
          window.open('https://docs-age.ipblocker.io/');
        },
        btnContent: 'Read user guideline',
        icon: BookIcon,
      },
      {
        title: 'Discover use cases',
        description: 'Explore our helpful articles on various rule use cases to assist you in verifying age of your store.',
        onAction: () => {
          window.open('https://docs-age.ipblocker.io/settings');
        },
        btnContent: 'View use cases',
        icon: GlobeAsiaFilledIcon,
      },
    ];
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          handleGa4(Ga4Event.ImpressTwitter);
        }
      });
    });

    if (twitterRef.current) {
      observer.observe(twitterRef.current);
    }

    // Cleanup observer khi component unmount
    return () => {
      if (twitterRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        observer.unobserve(twitterRef.current);
      }
    };
  }, [gaFour, handleGa4, twitterRef]);

  const handleRedirectToCheckoutRuleApp = () => {
    window.open(
      'https://apps.shopify.com/blockify?utm_campaign=crs&utm_source=age&utm_medium=homeinapp',
      '_blank',
    );
  };

  const handleCloseBannerReferApp = useCallback(() => {
    setShowBannerReferApp(false);
    dispatch(bannerSlice.actions.handleShowReferApp(false));
  }, [dispatch]);

  useLayoutEffect(() => {
    const timer = setTimeout(() => {
      if (showReferApp && location.pathname === PATH.DEFAULT.pathname) {
        setShowBannerReferApp(true);
      }
    }, 2000);

    return () => clearTimeout(timer);
  }, [showReferApp, location.pathname]);

  return (
    <>
      {config.embedded === Embedded.LIVE ? <BannerStype>
        <Page>
          <Collapsible
            id="banner-check-refer-app"
            open={showBannerReferApp}
            transition={{ duration: '200ms', timingFunction: 'ease-in-out' }}
          >
            <div className="banner-refer-app">
              <Banner
                onDismiss={handleCloseBannerReferApp}
                tone="info"
                title=""
                hideIcon
              >
                <div className='banner-wrapper-content' style={{ display: "flex", justifyContent: "space-between" }} >
                  <div className="d-flex banner-refer-app-content">
                    <img className="banner-refer-app-logo" src={images.blockify} alt="banner-refer-checkout-rule"></img>
                    <RegularText>
                      Block unwanted access from specific IPs, and countries. Prevent fraud with automated cancellation of high-risk orders.
                    </RegularText>

                  </div>
                  <div className="btn-set-up" style={{ marginRight: "0.5em" }}>
                    <Button target="_blank" onClick={handleRedirectToCheckoutRuleApp}>
                      Set up now
                    </Button>
                  </div>
                </div>
              </Banner>
            </div>
          </Collapsible>
        </Page>
      </BannerStype> : null}

      <Layout isBigHeader title={`Hello ${fetchDataSettings.data?.data?.nameShop},`}>
        <DashboardStyled>
          <div className="fz-18">
            <Text variant="bodyLg" as="span">
              Welcome to Age Verification Popup 🎉
            </Text>
          </div>

          {checkEmbeddedStatus.data && (
            <Collapsible
              id="banner-check-embedded-app"
              open={bannerNoticeEmbeddedApp && !checkEmbeddedStatus.data?.status}
              transition={{ duration: '200ms', timingFunction: 'ease-in-out' }}
            >
              <div className="mt-16">
                <Banner
                  onDismiss={() => {
                    dispatch(bannerSlice.actions.handleBannerNoticeEmbeddedApp(false));
                  }}
                  tone="warning"
                  title="Age Verification Popup is not activated"
                >
                  <div className="d-flex flex-column">
                    <RegularText>
                      Please enable the app by clicking the button below, and then click 'Save' in your Theme Editor. The popup will
                      not appear on your Online store until the app is enabled.
                    </RegularText>
                    <div className="mt-8">
                      <Button target="_blank" url={link.isOpenAppEmbedUrl}>
                        Activate app
                      </Button>
                    </div>
                  </div>
                </Banner>
              </div>
            </Collapsible>
          )}

          <ThingsToDoNext
            embedStatus={checkEmbeddedStatus.data?.status}
            hasCustomize={fetchDataSettings.data?.data.customizedSettings || false}
            hasTestApp={fetchDataSettings.data?.data.completedSetup || false}
            shop={fetchDataSettings.data?.data.shop || ''}
            isLoadingFetchDataSettings={fetchDataSettings.isLoading}
          />

          <div className="mt-16">
            <Overview />
          </div>
          <div className="card-info">
            <InlineGrid columns={{ xl: 3, lg: 3, md: 3, sm: 1, xs: 1 }} gap={'400'}>
              {infoAndSupport.map((item, index) => {
                return (
                  <Card key={index}>
                    <div className="card-container">
                      <div>
                        <div className="d-flex">
                          <Icon source={item.icon} />
                          <div className="ml-8">
                            <BoldText>{item.title}</BoldText>
                          </div>
                        </div>
                        <div className="card-text">
                          <RegularText>{item.description}</RegularText>
                        </div>
                      </div>
                      <div>
                        <Button onClick={item.onAction}>{item.btnContent}</Button>
                      </div>
                    </div>
                  </Card>
                );
              })}
            </InlineGrid>
          </div>

          <div className="mt-16">
            <FAQ></FAQ>
          </div>

          <div className="mt-16" ref={twitterRef}>
            <BannerTemplate src={images.twitter}>
              <BoldText>Bad tracking = lost sales. Fix your Twitter/X tracking in minutes now!</BoldText>
              <div className="mt-8">
                <RegularText>
                  <b>First & only app with CAPI & advanced analytics for Twitter Ads.</b> No more lost data from iOS 14.5 or ad
                  blockers. Detect exactly which ads drive sales, access all time ad analytics, and optimize your strategy to boost
                  ROI like never before.
                </RegularText>
              </div>
              <div className="mt-16">
                <Button
                  url="https://apps.shopify.com/twitter-multi-pixels?utm_campaign=crs&utm_source=age&utm_medium=homeinapp"
                  target="_blank"
                  variant="primary"
                  onClick={() => {
                    handleGa4(Ga4Event.ClickTwitter);
                  }}
                >
                  Set up now
                </Button>
              </div>
            </BannerTemplate>
          </div>

          <div className="app-recommended" ref={recommendAppRef}>
            <RecommenedApps />
          </div>
        </DashboardStyled>
      </Layout>
    </>
  );
};
export default Dashboard;
