import BoldText from '@/components/BoldText';
import { handleChangePreview, previewSelector } from '@/redux/slice/preview.slice';
import { Editor } from '@monaco-editor/react'
import { Card, Divider } from '@shopify/polaris';
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';

function CustomCSS() {
  const dispatch = useDispatch();
  const previewDetail = useSelector(previewSelector);

  return (
    <Card>
      <BoldText>CSS</BoldText>
      <div className='mt-8 mb-8'>
        <Divider />
      </div>
      <Editor
        height="400px"
        onChange={(value) => dispatch(handleChangePreview({ customCss: value }))}
        defaultLanguage="css"
        theme="vs-dark"
        defaultValue={previewDetail.customCss || ''}
        options={{
          automaticLayout: true,
        }}
        value={previewDetail.customCss || ''}
      />
    </Card>
  )
}

export default CustomCSS