import BoldText from '@/components/BoldText';
import { DateOrder, InputMethod, PageType, StatusType } from '@/constants/enum';
import { choiceMethodList, optionAgeList, optionDateOrderList, status, triggerCondition } from '@/constants/options';
import SelectCustomPage from '@/pages/Settings/components/CustomPage';
import ProductOrCollectionList from '@/pages/Settings/components/ProductOrCollectionList';
import { handleChangePreview, previewSelector } from '@/redux/slice/preview.slice';
import { BlockStack, Card, ChoiceList, Divider, RadioButton, Select, TextField } from '@shopify/polaris';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

function Info({ error, setError }: { error: string, setError: React.Dispatch<React.SetStateAction<string>> }) {
  const dispatch = useDispatch();
  const previewDetail = useSelector(previewSelector);
  const [errorProductOrCollection, setErrorProductOrCollection] = useState('');
  const [listUrl, setListUrl] = useState<{ value: string; isEdit: boolean; error?: string; index: number }[]>([]);
  const dateOrderHelpText = {
    [DateOrder.YY_MM_DD]: 'This format displays the date as 2024-May-01.',
    [DateOrder.MM_DD_YY]: 'This format displays the date as May-01-2024.',
    [DateOrder.DD_MM_YY]: 'This format displays the date as 01-May-2024.',
    [DateOrder.YY_DD_MM]: 'This format displays the date as 2024-01-May.',
    [DateOrder.MM_YY_DD]: 'This format displays the date as May-2024-01.',
    [DateOrder.DD_YY_MM]: 'This format displays the date as 01-2024-May.',
  };
  const handleChangeTemplate = (key: keyof typeof previewDetail) => (value: string) => {
    const newValue = key === 'status' ? value === StatusType.Enable : value;

    dispatch(
      handleChangePreview({
        [key]: newValue,
      }),
    );
  };


  const handleChangeChoiceList = (key: keyof typeof previewDetail) => (selected: string[]) => {
    if (errorProductOrCollection) {
      setErrorProductOrCollection('');
    }

    dispatch(
      handleChangePreview({
        [key]: selected[0],
        showInPages: key === 'pageTypeToShow' ? [] : previewDetail.showInPages,
      }),
    );
  };
  return (
    <BlockStack gap="200">
      <Card>
        <BoldText>Pop-up info</BoldText>
        <div className='mt-8 mb-8'>
          <Divider />
        </div>
        <BlockStack gap="200">
          <Select
            value={!!previewDetail.status ? StatusType.Enable : StatusType.Disable}
            label={<BoldText>Status</BoldText>}
            options={status}
            onChange={handleChangeTemplate('status')}
          />

          <TextField
            autoComplete=""
            label={<BoldText>Name*</BoldText>}
            value={previewDetail?.name || ''}
            onChange={(value) => {
              if (!value?.trim()?.length) {
                setError('Rule name is required.');
              } else {
                if (error.length) {
                  setError("");
                }
              }
              handleChangeTemplate('name')(value);
            }}
            maxLength={255}
            showCharacterCount
            placeholder='Enter your pop-up name'
            helpText='For internal reference. Only you can see it.'
            error={error}
          />

          <Select
            value={previewDetail.minAge}
            label={<BoldText>Verify age</BoldText>}
            options={optionAgeList}
            onChange={handleChangeTemplate('minAge')}
          />

          <ChoiceList
            choices={choiceMethodList}
            selected={[String(previewDetail.inputMethod)]}
            title={<BoldText>Method</BoldText>}
            onChange={handleChangeChoiceList('inputMethod')}
          />
          {previewDetail.inputMethod === InputMethod.BIRTHDATE_ENTRY &&
            <Select
              options={optionDateOrderList}
              label={<BoldText>Date order</BoldText>}
              value={previewDetail.dateTimeFormat}
              onChange={handleChangeTemplate('dateTimeFormat')}
              helpText={dateOrderHelpText[previewDetail.dateTimeFormat]}
            />
          }
        </BlockStack>
      </Card>

      <Card>
        <BoldText>Condition</BoldText>
        <div className='mt-8 mb-8'>
          <Divider />
        </div>
        <BlockStack gap="200">
          <BlockStack gap="050">
            <BoldText>Display page(s)</BoldText>
            <BlockStack>
              <RadioButton
                label="All pages"
                checked={previewDetail.pageTypeToShow === PageType.All}
                id={PageType.All}
                onChange={() => handleChangeChoiceList('pageTypeToShow')([PageType.All])}
              />
              <RadioButton
                label="Home page"
                checked={previewDetail.pageTypeToShow === PageType.Home}
                id={PageType.Home}
                onChange={() => handleChangeChoiceList('pageTypeToShow')([PageType.Home])}
              />
              <RadioButton
                label="Specific collections"
                checked={previewDetail.pageTypeToShow === PageType.SpecificCollections}
                id={PageType.SpecificCollections}
                onChange={() => handleChangeChoiceList('pageTypeToShow')([PageType.SpecificCollections])}
              />
              {previewDetail.pageTypeToShow === PageType.SpecificCollections ?
                <ProductOrCollectionList
                  errorProductOrCollection={errorProductOrCollection}
                  setErrorProductOrCollection={setErrorProductOrCollection}
                /> : null}
              <RadioButton
                label="Specific products"
                checked={previewDetail.pageTypeToShow === PageType.SpecificProducts}
                id={PageType.SpecificProducts}
                onChange={() => handleChangeChoiceList('pageTypeToShow')([PageType.SpecificProducts])}
              />
              {previewDetail.pageTypeToShow === PageType.SpecificProducts ?
                <ProductOrCollectionList
                  errorProductOrCollection={errorProductOrCollection}
                  setErrorProductOrCollection={setErrorProductOrCollection}
                /> : null}
              <RadioButton
                label="Custom"
                checked={previewDetail.pageTypeToShow === PageType.Custom}
                id={PageType.Custom}
                onChange={() => handleChangeChoiceList('pageTypeToShow')([PageType.Custom])}
              />
              {
                previewDetail.pageTypeToShow === PageType.Custom ?
                  <SelectCustomPage listUrl={listUrl} setListUrl={setListUrl} /> : null
              }
            </BlockStack>
          </BlockStack>
          {/* <Collapsible
            open={previewDetail.pageTypeToShow !== PageType.All}
            id="select-specific-page"
            expandOnPrint
            transition={{ duration: '300ms', timingFunction: 'ease-in-out' }}
          >
            <Select
              value={previewDetail.pageTypeToShow}
              options={choiceDisplayOptionSpecific}
              label={<BoldText>Select specific pages</BoldText>}
              onChange={(value) => handleChangeChoiceList('pageTypeToShow')([value])}
            />
            <ProductOrCollectionList
              errorProductOrCollection={errorProductOrCollection}
              setErrorProductOrCollection={setErrorProductOrCollection}
            />
            <SelectCustomPage listUrl={listUrl} setListUrl={setListUrl} />
          </Collapsible> */}
          <ChoiceList
            choices={triggerCondition}
            selected={[String(previewDetail.applyCustomer)]}
            title={<BoldText>Trigger condition</BoldText>}
            onChange={handleChangeChoiceList('applyCustomer')}
          />
        </BlockStack>
      </Card>
    </BlockStack>
  )
}

export default Info