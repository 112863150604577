/* eslint-disable jsx-a11y/anchor-is-valid */
import BoldText from '@/components/BoldText';
import RegularText from '@/components/RegularText';
import { useGa4 } from '@/hooks/useGa4';
import { gaFourSelector } from '@/redux/slice/session.slice';
import {
  ActionList,
  Badge,
  BlockStack,
  Box,
  Button,
  Card,
  Collapsible,
  Icon,
  InlineGrid,
  InlineStack,
  Popover,
  Text,
} from '@shopify/polaris';
import { AlertTriangleIcon, MenuHorizontalIcon } from '@shopify/polaris-icons';
import { memo, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { recommendActions } from './recommendAction';
import { IGA4Actions } from '@/types';
import bannerSlice, { showRecommendActionSelector } from '@/redux/slice/banner.slice';
import { RecommendedActionStyled } from './styled';

const RecommendedApps = () => {
  const dispatch = useDispatch();
  const gaFour = useSelector(gaFourSelector);
  const { handleGa4 } = useGa4();
  const showRecommendActions = useSelector(showRecommendActionSelector);

  const [popoverActive, setPopoverActive] = useState(false);

  const togglePopoverActive = useCallback(() => {
    setPopoverActive(!popoverActive);
  }, [popoverActive]);

  const handleClick = useCallback(
    (item: IGA4Actions) => () => {
      window.open(item.href, '_blank', 'noopener,noreferrer');
      if (!gaFour[item.key as keyof typeof gaFour]) {
        handleGa4(item.clickGA);
      }
    },
    [gaFour, handleGa4],
  );

  const handleCloseRecommend = useCallback(() => {
    dispatch(bannerSlice.actions.handleShowRecommendActions(false));
  }, [dispatch]);

  const activator = <Button onClick={togglePopoverActive} variant="plain" icon={MenuHorizontalIcon}></Button>;

  return (
    <RecommendedActionStyled>
      <Collapsible
        open={showRecommendActions}
        id="basic-collapsible-1"
        transition={{ duration: '500ms', timingFunction: 'ease-in-out' }}
        expandOnPrint
      >
        <Box paddingBlockStart={'500'}>
          <Card>
            <BlockStack gap={'200'}>
              <InlineGrid columns="1fr auto">
                <div>
                  <Badge tone="success">Suggested by experts</Badge>
                  <div className="btn-cancel"></div>
                </div>
                <Popover active={popoverActive} activator={activator} onClose={togglePopoverActive}>
                  <ActionList actionRole="menuitem" items={[{ content: 'Dismiss', onAction: handleCloseRecommend }]} />
                </Popover>
              </InlineGrid>
            </BlockStack>

            <InlineStack gap={'050'}>
              <div style={{ maxHeight: '2em' }}>
                <Icon source={AlertTriangleIcon} tone="critical" />
              </div>
              <Text variant="headingMd" as="h2">
                Age-restricted store is at greater risk of revenue loss & disruption without a reliable safety system
              </Text>
            </InlineStack>

            <div className="mt-8">
              <RegularText>
                With the right tools, you can minimize risks, protect your profits. Focus your energy on growth rather than
                concerns.
              </RegularText>
            </div>
            <div className="mt-16" id="banner-2">
              <InlineGrid columns={{ xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }} gap={'400'}>
                {recommendActions.map((item) => {
                  return (
                    <Card padding={'400'} key={item.title}>
                      <div className="recommend-app-container">
                        <div className="recommend-app-content">
                          <div className="d-flex align-center">
                            <div>{item.icon}</div>
                            <BoldText>{item.title}</BoldText>
                          </div>
                          <Text as="p">{item.description}</Text>
                        </div>

                        <div>
                          <Button onClick={handleClick(item)}>{item.buttonLabel}</Button>
                        </div>
                      </div>
                    </Card>
                  );
                })}
              </InlineGrid>
            </div>
          </Card>
        </Box>
      </Collapsible>
    </RecommendedActionStyled>
  );
};

export default memo(RecommendedApps);
