import { PATH } from '@/constants/path';
import CheckoutVerification from '@/pages/CheckoutVerification';
import CheckoutVerificationSetup from '@/pages/CheckoutVerificationSetup';
import CustomTemplate from '@/pages/CustomTemplate';
import Dashboard from '@/pages/Dashboard';
import PopupCustomization from '@/pages/PopupCustomization';
import Settings from '@/pages/Settings';
import Translation from '@/pages/Translation';
import TranslationSetup from '@/pages/TranslationSetup';
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

interface IRouter {
  path: string;
  element: React.ReactNode;
}

const router: Array<IRouter> = [
  {
    path: PATH.DEFAULT.pathname,
    element: <Dashboard />,
  },
  {
    path: PATH.POP_UP_CUSTOMIZATION.pathname,
    element: <PopupCustomization />,
  },
  {
    path: PATH.SETTINGS.pathname,
    element: <Settings />,
  },
  {
    path: PATH.CUSTOM_TEMPLATE.pathname,
    element: <CustomTemplate />,
  },
  {
    path: PATH.CHECKOUT_VERIFICATION.pathname,
    element: <CheckoutVerification />,
  },
  {
    path: PATH.CHECKOUT_VERIFICATION_SETUP.pathname,
    element: <CheckoutVerificationSetup />,
  },
  {
    path: PATH.TRANSLATION.pathname,
    element: <Translation />,
  },
  {
    path: `${PATH.TRANSLATION_SETUP.pathname}/:language/:id`,
    element: <TranslationSetup />
  }
];

function RenderRouter(): JSX.Element {
  return (
    <Routes>
      {router.map((item: IRouter) => (
        <Route key={item.path} path={item.path} element={item.element} />
      ))}
      <Route path="*" element={<Navigate to={PATH.DEFAULT} replace />} />
    </Routes>
  );
}
export default RenderRouter;
