import Layout from '@/components/layout';
import { config } from '@/config';
import { apiCaller } from '@/redux/query';
import { isSkipApiSelector } from '@/redux/slice/auth.slice';
import {
  Box,
  Button,
  Card,
  Icon,
  InlineGrid,
  Text,
} from '@shopify/polaris';
import { LanguageTranslateIcon } from '@shopify/polaris-icons';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { TranslationStyled } from './styled';
import TranslationList from './components/Table';

function Translation() {
  const isSkipApi = useSelector(isSkipApiSelector);
  const [fetchLanguages, languageStatus] = apiCaller.useLazyGetLanguageQuery();

  const generalDetailQuerySettings = apiCaller.useGetGeneralDetailQuery("", { skip: isSkipApi });

  const defaultLanguage = languageStatus && languageStatus?.data?.data?.find((item) => item.primary);

  const handleRefreshData = () => {
    fetchLanguages({ shop: config.shop });
  };

  useEffect(() => {
    fetchLanguages({ shop: config.shop });
  }, [fetchLanguages]);

  return (
    <Layout
      title="Translation"
      subtitle='Translate your widgets to multiple languages'
      primaryAction={
        <Button onClick={handleRefreshData} loading={languageStatus.isFetching}>
          Refresh data
        </Button>
      }
    >
      <TranslationStyled>
        <Card>
          <InlineGrid columns={"auto 1fr"} gap={"050"}><Icon source={LanguageTranslateIcon} /><Text as="h2" variant="headingSm">Default language: {defaultLanguage?.name}</Text></InlineGrid>
          <Text as='h2' variant="bodySm">All widgets are identified with this language by default.</Text>
          <Box paddingBlockStart={"200"}>
            <Button onClick={() => window.open(
              `https://admin.shopify.com/store/${generalDetailQuerySettings.data?.data?.shop.replace(
                '.myshopify.com',
                '',
              )}/settings/languages`,
              '_blank',
            )}>
              Change default
            </Button>
          </Box>
        </Card>
        <div className="mt-16">
          <TranslationList />
        </div>
      </TranslationStyled>
    </Layout>
  );
}

export default Translation;
