import { images } from '@/asset';
import RegularText from '@/components/RegularText';
import { BackgroundType, InputMethod, PageType, TriggerType } from '@/constants/enum';
import { displayPageList, triggerCondition } from '@/constants/options';
import { PATH } from '@/constants/path';
import { checkShowErrorInline, formatCreatedAt, handleToastMutation } from '@/helpers';
import { apiCaller } from '@/redux/query';
import { isSkipApiSelector } from '@/redux/slice/auth.slice';
import { handleCustomizeTemplate, handlePopupListTable, popupListTableSelector } from '@/redux/slice/preview.slice';
import toastSlice from '@/redux/slice/Toast/toast.slice';
import { Badge, Button, EmptyState, IndexFilters, IndexFiltersMode, IndexTable, Text, Modal, Pagination, SkeletonBodyText, Tooltip, useIndexResourceState, useSetIndexFiltersMode } from '@shopify/polaris';
import { DeleteIcon, EditIcon } from '@shopify/polaris-icons';
import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import _debounce from 'lodash/debounce';
import { IParamsApi } from '@/types/apis/params';
import { PopupListFilter } from '../Filters/Filter.popup';

interface TablePopupListProps {
  onParentAction?: (action: () => void) => void;
}

function PopupList({ onParentAction }: TablePopupListProps) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isSkip = useSelector(isSkipApiSelector);
  const popupListTable = useSelector(popupListTableSelector);
  const [inputSearch, setInputSearch] = useState('');
  const { mode, setMode } = useSetIndexFiltersMode();
  const [state, setState] = useState({
    itemSelected: -1,
    isOpenModalDelete: false,
  });
  const [deleteAll, setDeleteAll] = useState(false);

  const { data, isFetching, isLoading } = apiCaller.useGetPopupListQuery(
    {
      ...popupListTable,
    },
    { skip: isSkip },
  );
  const [popup, popupStatus] = apiCaller.useLazyGetSettingsQuery();
  const [deleteItem, deleteItemStatus] = apiCaller.useDeleteRuleMutation();
  const [deleteAllRule, { isLoading: deleteAllLoading }] = apiCaller.useDeleteAllRuleMutation();
  const handleCloseModalDelete = useCallback(() => {
    setState({
      itemSelected: -1,
      isOpenModalDelete: false,
    });
    setDeleteAll(false);
  }, []);

  const handleOpenModalDelete = useCallback(
    (id: number) => () => {
      setState({
        itemSelected: id,
        isOpenModalDelete: true,
      });
    },
    [],
  );
  const handleEdit = useCallback(
    async (id: string) => {
      const response = await popup(id);
      if (response.data && response.data.setting && !popupStatus.isLoading) {
        const rule = {
          id: response.data.setting.id,
          shop: response.data.setting.shop,
          mainBackgroundType: response.data.setting.mainBackgroundType.toString() as BackgroundType,
          mainBackground: response.data.setting.mainBackground,
          popupBackground: response.data.setting.popupBackground,
          logo: response.data.setting.logo,
          pageTypeToShow: response.data.setting.pageTypeToShow,
          showInPages: response.data.setting.showInPages,
          minAge: response.data.setting.minAge.toString(),
          headingText: response.data.setting.headingText,
          headingSize: response.data.setting.headingSize,
          headingColor: response.data.setting.headingColor,
          subHeadingText: response.data.setting.subHeadingText,
          subHeadingSize: response.data.setting.subHeadingSize,
          subHeadingColor: response.data.setting.subHeadingColor,
          submitButtonBackground: response.data.setting.submitButtonBackground,
          submitButtonTextColor: response.data.setting.submitButtonTextColor,
          submitButtonLabel: response.data.setting.submitButtonLabel,
          cancelButtonBackground: response.data.setting.cancelButtonBackground,
          cancelButtonTextColor: response.data.setting.cancelButtonTextColor,
          cancelButtonLabel: response.data.setting.cancelButtonLabel,
          inputMethod: response.data.setting.inputMethod.toString() as InputMethod,
          dateTimeFormat: response.data.setting.dateTimeFormat,
          customCss: response.data.setting.customCss,
          monthsLabel: {
            january: response.data.setting.monthsLabel?.january || '',
            february: response.data.setting.monthsLabel?.february || '',
            march: response.data.setting.monthsLabel?.march || '',
            april: response.data.setting.monthsLabel?.april || '',
            may: response.data.setting.monthsLabel?.may || '',
            june: response.data.setting.monthsLabel?.june || '',
            july: response.data.setting.monthsLabel?.july || '',
            august: response.data.setting.monthsLabel?.august || '',
            september: response.data.setting.monthsLabel?.september || '',
            october: response.data.setting.monthsLabel?.october || '',
            november: response.data.setting.monthsLabel?.november || '',
            december: response.data.setting.monthsLabel?.december || '',
          },
          errorMessage: response.data.setting.errorMessage,
          redirectUrl: response.data.setting.redirectUrl,
          lastUpdate: response.data.setting.lastUpdate,
          showCustomMonthLabel: response.data.setting.showCustomMonthLabel,
          showWatermark: response.data.setting.showWatermark,
          customPages: response.data.setting.customPages ?? [],
          status: response.data.setting.status,
          name: response.data.setting.name,
          applyCustomer: response.data.setting.applyCustomer?.toString() as TriggerType,
        };
        dispatch(handleCustomizeTemplate(rule));
        navigate(PATH.CUSTOM_TEMPLATE);
      }
    },
    [dispatch, navigate, popup, popupStatus.isLoading],
  );


  const items = useMemo(() => {
    return data?.rules.map((rule) => {
      const triggerLabel = triggerCondition.find(condition => condition.value === rule.applyCustomer.toString())?.label;
      const targetLabel = displayPageList.find(page => page.value === rule.pageTypeToShow.toString())?.label ?? 'None';

      return {
        id: rule.id.toString(),
        name: rule.name,
        target: targetLabel,
        trigger: triggerLabel,
        lastUpdatedAt: (
          <RegularText>{rule.lastUpdate ? formatCreatedAt(rule.lastUpdate * 1000) : ''}</RegularText>
        ),
        status: rule.status === 1 ? <Badge tone='success'>Enable</Badge> : <Badge tone="enabled">Disable</Badge>,
        action: (
          <div className='btn-container'>
            <div className="absolute d-flex">
              <div className="control-btn edit-btn">
                <Tooltip content="Edit">
                  <Button
                    icon={EditIcon}
                    onClick={() => handleEdit(rule.id.toString())}
                    variant="plain"
                  />
                </Tooltip>
              </div>
              <div className="control-btn remove-btn">
                <Tooltip content="Delete">
                  <Button icon={DeleteIcon} onClick={handleOpenModalDelete(rule.id)} variant="plain" />
                </Tooltip>
              </div>
            </div>
          </div>
        ),

      };
    });
  }, [data, handleEdit, handleOpenModalDelete]);

  const selectableRules = items?.filter((item) => item.id) ?? [];

  const { selectedResources, allResourcesSelected, handleSelectionChange, clearSelection } = useIndexResourceState(selectableRules);
  onParentAction?.(clearSelection);

  const handleDelete = useCallback(
    (id: string) => () => {
      deleteItem(JSON.stringify([id])).then((res) => {
        const condition = checkShowErrorInline(res);
        if (!condition.statusCode) {
          dispatch(toastSlice.actions.handleToast(handleToastMutation(res)));

          if (data && data.meta.total % Number(popupListTable.limit) === 1) {
            dispatch(handlePopupListTable({
              ...popupListTable,
              page: popupListTable.page - 1 || 1,
            }));
          }
          clearSelection();
          handleCloseModalDelete();
        }
      });
    },
    [popupListTable, data, deleteItem, dispatch, handleCloseModalDelete, clearSelection],
  );

  const handleDeleteAllRule = useCallback(
    async () => {
      try {
        const res = await deleteAllRule();
        if ("data" in res) {
          dispatch(toastSlice.actions.handleToast(handleToastMutation(res)));
        }
      } catch (err) {
        console.log(err);
      }
      handleCloseModalDelete();
    },
    [toastSlice],
  );


  const handleDeleteSelected = useCallback(async () => {
    try {
      const res = await deleteItem(JSON.stringify(selectedResources));
      const condition = checkShowErrorInline(res);

      if (!condition.statusCode) {
        dispatch(toastSlice.actions.handleToast(handleToastMutation(res)));
        if (data?.meta.total === selectedResources.length) {
          dispatch(
            handlePopupListTable({
              ...popupListTable,
              page: popupListTable.page - 1 || 1,
            }),
          );
        }
      }
    } catch (error) {
      console.error('Error while deleting selected resources:', error);
    }
    handleCloseModalDelete();
    clearSelection();
  }, [clearSelection, deleteItem, dispatch, handleCloseModalDelete, popupListTable, selectedResources, data?.meta.total]);

  const debounceSetSearchRule = useCallback(
    _debounce((value: IParamsApi.IPopupList) => {
      dispatch(handlePopupListTable(value));
    }, 1000),
    [],
  );

  const handleOpenModalDeleteAll = useCallback(() => {
    setState({
      ...state,
      isOpenModalDelete: true,
    });
    setDeleteAll(true);
  }, [state]);

  const handleInputChangeTable = useCallback(
    (search: string) => {
      setInputSearch(search);
      debounceSetSearchRule({
        ...(() => {
          const { search, ...rest } = popupListTable;
          return rest;
        })(),
        page: search ? 1 : popupListTable.page,
        ...(search !== '' && { search: search }),
      });
    },
    [popupListTable, debounceSetSearchRule],
  );

  const handleClearAllFilter = useCallback(() => {
    dispatch(
      handlePopupListTable({
        ...(() => {
          const { applyCustomer, pageTypeToShow, search, ...rest } = popupListTable;
          return rest;
        })(),
        page: 1,
        limit: '10'
      }),
    );
  }, [popupListTable, dispatch]);

  const promotedBulkActions = [
    {
      content: 'Cancel',
      onAction: () => clearSelection(),
    },
    {
      content: 'Delete',
      onAction: handleOpenModalDelete(-1),
    },
  ];

  const rowMarkup = useMemo(() => {
    return items?.map(({ id, name, target, trigger, status, lastUpdatedAt, action }, index) => (
      <IndexTable.Row onClick={() => { }} id={id} key={id} position={index} selected={selectedResources.includes(id)}>
        <IndexTable.Cell><Tooltip content={name}><Text as="h2" variant='bodySm'>{name?.length > 30 ? name?.slice(0, 30) + '...' : name}</Text></Tooltip></IndexTable.Cell>
        <IndexTable.Cell>{target}</IndexTable.Cell>
        <IndexTable.Cell>{trigger}</IndexTable.Cell>
        <IndexTable.Cell>{lastUpdatedAt}</IndexTable.Cell>
        <IndexTable.Cell>{status}</IndexTable.Cell>
        <IndexTable.Cell>{action}</IndexTable.Cell>
      </IndexTable.Row>
    )) ?? [];
  }, [items, selectedResources]);

  const resourceName = {
    singular: 'rule',
    plural: 'rules',
  };

  return (
    <div>
      {mode === IndexFiltersMode.Filtering ? null : (
        <div className="btn-delete">
          <Tooltip content="Delete all">
            <Button icon={DeleteIcon} onClick={handleOpenModalDeleteAll} disabled={!items?.length} />
          </Tooltip>
        </div>
      )}
      <IndexFilters
        queryValue={inputSearch}
        queryPlaceholder="Searching by pop-up name"
        onQueryChange={handleInputChangeTable}
        onQueryClear={() => {
          handleInputChangeTable('');
        }}
        tabs={[]}
        selected={0}
        onSelect={() => { }}
        canCreateNewView={false}
        filters={PopupListFilter().filters}
        appliedFilters={PopupListFilter().appliedFilters}
        onClearAll={handleClearAllFilter}
        cancelAction={{ onAction: () => { } }}
        mode={mode}
        setMode={setMode}
        disabled={!items?.length && !popupListTable.search?.length && !popupListTable.applyCustomer && !popupListTable.pageTypeToShow}
      />
      <div className="pd-16">
        <div className="table-block table-block-blacklist">
          <IndexTable
            emptyState={
              isLoading ? (
                <SkeletonBodyText lines={16} />
              ) : (
                <EmptyState
                  heading="Whoops! You don't have any pop-up yet."
                  image={images.emptyState}
                >
                  <RegularText>Create and customize a new pop-up now to start verify customer’s age.</RegularText>
                </EmptyState>
              )
            }
            onSelectionChange={handleSelectionChange}
            resourceName={resourceName}
            itemCount={items?.length ?? 0}
            headings={[
              { title: 'Pop-up name' },
              { title: 'Target' },
              { title: 'Trigger condition' },
              { title: 'Last updated' },
              { title: 'Status' },
              { title: 'Action' },
            ]}
            promotedBulkActions={promotedBulkActions}
            selectedItemsCount={allResourcesSelected ? 'All' : selectedResources.length}
          >
            {isLoading ? <SkeletonBodyText lines={16} /> : rowMarkup}
          </IndexTable>

          <div className="mt-16 pb-16">
            {data && data?.meta.total ? (
              <Pagination
                label={
                  data?.meta.total
                    ? `Showing ${(data.meta.currentPage - 1) * Number(popupListTable.limit) + 1} to ${Math.min(
                      data.meta.currentPage * Number(popupListTable.limit),
                      data?.meta.total,
                    )} of ${data?.meta.total} items`
                    : null
                }
                hasPrevious={!isFetching && data && data.meta.currentPage > 1}
                onPrevious={() => {
                  if (data) {
                    dispatch(handlePopupListTable({
                      ...popupListTable,
                      page: data && data.meta.currentPage - 1,
                    }),
                    );
                  }
                }}
                hasNext={!isFetching && data && data.meta.currentPage < Math.ceil(data.meta.total / Number(popupListTable.limit))}
                onNext={() => {
                  if (data) {
                    dispatch(handlePopupListTable({
                      ...popupListTable,
                      page: data.meta.currentPage + 1,
                    }),
                    );
                  }
                }}
              />
            ) : null}
          </div>
        </div>

        <Modal
          open={state.isOpenModalDelete}
          onClose={handleCloseModalDelete}
          title={state.itemSelected === -1 ? 'Do you want to delete all selected rules' : 'Delete rule'}
          primaryAction={{
            destructive: true,
            content: 'Delete',
            onAction: deleteAll ? handleDeleteAllRule : (state.itemSelected === -1 ? handleDeleteSelected : handleDelete(state.itemSelected.toString())),
            loading: deleteItemStatus.isLoading || deleteAllLoading,
          }}
          secondaryActions={[
            {
              content: 'Cancel',
              onAction: handleCloseModalDelete,
            },
          ]}
        >
          <Modal.Section>
            <RegularText>If you delete the rule, you won't be able to revert it.</RegularText>
          </Modal.Section>
        </Modal>
      </div>
    </div>
  );
};

export default PopupList